$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  fill: #ffffff;
  padding: 0 5px 0 5px;
  transition: all 0.2s ease-in-out;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button:hover {
  background-color: rgba(255,255,255,.5);
  fill: $signal;
}

.embla__button__svg {
  width: 30px;
  height: 30px;
}

.embla__buttonPrev {
  left: 0;
}

.embla__buttonNext {
  right: 0;
}