@mixin contact-banner-title {
  @include readability-text-shadow;
  @include font-special;

  position: absolute;
  z-index: 1;
  width: auto;
  top: 4rem;
  margin-left: 0.5rem;
  padding-right: 1.5rem;
  font-size: 4rem;

  @include media-breakpoint-up(sm) {
    font-size: 5rem;
    text-align: right;
  }

  @include media-breakpoint-up(md) {
    font-size: 6.5rem;
    top: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 6.5rem;
    text-align: left;
    left: 14rem;
  }
}
