$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.embla {
  position: relative;
  background-color: #f7f7f7;
  //padding: 20px;
  //max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  //cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -5px;
}

.embla__buttons {
  @include slider-buttons-wrap;
}

.embla__dots {
  @include slider-dots-wrap;
  @include print-hidden;
}
