$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;

  .content {
    text-align: center;
    margin-bottom: 1.3rem;
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
    @include media-breakpoint-up(md) {
      padding-right: 5rem;
    }

    p {
      margin-bottom: 1.3rem;
    }
  }

  .cardTitle {
    @include font-content-subtitle;
  }

  .iconWrap {
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;

    // image placeholder styles
    position: relative;
    min-width: 110px;
    min-height: 110px;
    // end: image placeholder styles

    @include media-breakpoint-up(sm) {
      display: block;
    }

    img {
      width: 110px;
      height: auto;
    }
  }

  ul {
    text-align: left;
    @include branded-list;
  }

  table {
    text-align: left;
    margin: auto;
    vertical-align: top;

    @include media-breakpoint-up(sm) {
      margin: inherit;
    }
  }
}
