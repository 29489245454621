$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';
$fontColor: #fff;

.root {
  color: white;

  .titleWrap {
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }

    h2 {
      @include newsletter-form-title;
    }
  }

  .input {
    background-color: transparent;
    color: $fontColor;
    box-shadow: 0 0 5px -2px rgb(255 255 255 / 60%);

    &:-webkit-autofill {
      background-color: inherit;
      color: inherit;
    }

    &.select {
      //text-transform: uppercase;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-size: 16px 24px;

      & option {
        text-transform: uppercase;
        background-color: $secondary;

        &:first-child {
          display: none;
        }
      }
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: $fontColor;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: $fontColor;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: $fontColor;
    }
  }

  .submitWrap {
    .arrow {
      padding-top: 1.3rem;
      //color: $tertiary;
    }

    .submitButton {
      font-family: $newsletter-block-font-family;
      color: $newsletter-block-color;

      &:hover {
        color: $newsletter-block-color-hover;
      }
    }
  }

  .text {
    .gtcInfo {
      display: block;
      font-size: 0.7rem;
      font-style: italic;

      a {
        color: $fontColor;
      }
    }
  }
}
