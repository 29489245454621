@mixin newsletter-form-title {
  color: $newsletter-block-title-color;
  font-size: 4.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 5rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 6.5rem;
  }
}
