$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

$accordion-border-color: $gray-600;

.root {
  .accordionHeader {
    background: none;
    border: none;
    padding: 0;

    & button {
      background: none;
      @include font-content-title(false);

      font-size: 1.1rem;

      &:not(.collapsed) {
        box-shadow: none;
      }
    }
  }

  .accordionItem {
    background: none;
    border-color: $accordion-border-color;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    @at-root .unstyledWhite .accordionItem {
      border: none;
    }
    @include print-avoid-break-inside;
  }

  .accordion + .accordion:last-of-type {
    .accordionItem {
      border-bottom: $accordion-border-width solid $accordion-border-color;
    }
  }

  .accordionButton {
    color: $font-color-default;
    width: 100%;
    padding: 0.5rem;
    border: 0;
    text-align: left;

    @media print {
      padding-bottom: 0;
    }

    @at-root .unstyledWhite .accordionButton {
      color: $white;
      text-transform: none !important;
      font-weight: normal !important;
      font-size: 1em !important;
    }

    &:focus-visible {
      outline: 0;
    }

    &::after {
      // chevron down
      content: '';
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 1rem 0.8rem;
      position: absolute;
      right: 0.75rem;
      z-index: 6;
      top: 0.8rem;
      width: 1rem;
      height: 0.8rem;
      transition-duration: 0.2s;
      transition-property: transform;

      @at-root .unstyledWhite .accordionButton::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      }

      @include print-hidden;
    }

    &:hover,
    &.active {
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      }
    }

    @at-root .unstyledWhite .accordionButton {
      &.active {
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        }
      }

      &:hover {
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        }
      }
    }

    &.active::after {
      transform: rotate(-180deg);
    }
  }

  .cardBody {
    padding: 0 0.5rem 0.5rem 0.5rem;

    ul {
      @include branded-list;
    }
  }
}
